<template>
  <!-- 凭证接收 - 文件夹内 凭证 -->
  <div class="facilityBox image-detail" v-loading="pagination.loading">
    <!-- 面包屑 -->
    <el-breadcrumb separator="/" style="margin:0 0 10px 0;" v-if="!hideElement">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>凭证接收</el-breadcrumb-item>
      <el-breadcrumb-item>查看</el-breadcrumb-item>
      <el-breadcrumb-item>{{
        fileType == 1 ? "未关联" : "已关联"
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ folderObj.FolderName }}</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 图片列表 -->
    <div class="voucher-images">
      <div
        class="image-box"
        v-for="item in pagination.list"
        :key="item.FolderId"
      >
        <div class="image">
          <el-image
            class="img"
            @click="$emit('showImagePreview', true, item)"
            :src="item.FileURL"
            fit="contain"
          ></el-image>
          <i
            v-if="!hideElement && !folderObj.IsLock"
            @click="editImageName(item)"
            class="edit-name el-icon-edit"
          ></i>
        </div>
        <p class="image-name">{{ item.FolderName }}</p>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page.sync="pagination.page"
      :page-size="pagination.pagesize"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <!-- 返回 -->
    <el-button
      class="goBack"
      type="info"
      plain
      icon="el-icon-arrow-left"
      @click="$emit('showImageDetail', false)"
      v-if="!hideElement"
      >返回</el-button
    >
  </div>
</template>

<script>
import {
  GetEmUserFileAsync,
  UpdateUserFileNameAsync,
} from "@/api/voucherManagement/voucherManagement.js";
export default {
  name: "ImageDetail",
  props: ["userId", "folderObj", "fileType", "hideElement"],
  data() {
    return {
      pagination: {
        loading: false,
        page: 1,
        pagesize: 10,
        total: 0,
        list: [],
      },
    };
  },
  methods: {
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getImageVoucher();
    },

    // 修改图片名称
    editImageName(data) {
      this.$prompt("图片名称", `修改名称`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "mergePrompt",
        inputPlaceholder: "请输入图片名称，限25个汉字",
        inputValidator(value) {
          if (!value) {
            return "请输入图片名称";
          } else if (value && value.length > 25) {
            return "图片名称限25个汉字";
          }
        },
      })
        .then((e) => {
          UpdateUserFileNameAsync(data.FolderId, e.value, this.userId).then(
            (res) => {
              this.getImageVoucher();
              this.$message({
                message: "图片名称更改成功",
                type: "success",
              });
            }
          );
        })
        .catch(() => {});
    },

    // 获取文件夹下图片凭证
    getImageVoucher() {
      this.pagination.loading = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        folderId: this.folderObj.FolderId,
        userId: this.userId,
        type: "Desc",
      };

      GetEmUserFileAsync(params)
        .then((res) => {
          let { TotalCount, DataList } = res.data.data;
          this.pagination.total = TotalCount;
          this.pagination.list = DataList;
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-detail {
  .voucher-images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .image-box {
      width: 280px;
      margin: 0 72px 16px 0;

      &:nth-child(5n) {
        margin-right: 0;
      }

      .image {
        width: 100%;
        height: 180px;
        position: relative;

        .img {
          width: 100%;
          height: 100%;
        }

        .edit-name {
          position: absolute;
          right: 16px;
          bottom: 16px;
          font-size: 22px;
          cursor: pointer;
        }
      }

      p {
        width: 100%;
        text-align: center;
        margin-top: 8px;
      }
    }
  }

  .goBack {
    margin: 16px 0 0 48%;
  }
}
</style>

<style lang="scss">
.mergePrompt {
  .el-message-box__title {
    font-weight: bolder;
  }

  .el-message-box__content {
    display: flex;
    align-items: center;
    padding: 0px 15px;

    .el-message-box__input {
      width: 80%;
      margin-left: 8px;
    }
  }
}
</style>
